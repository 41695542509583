.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiPhoneNumber-flagButton{
  display: none !important;
}
.flag{
  width: max-content;
  height: 30px;
  border-radius: 4px;
  background-color: rgb(254, 237, 236);
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: rgb(200, 55, 45);
}
.flagSuccess{
  width: max-content;
  height: 30px;
  border-radius: 4px;
  background-color: rgb(237, 246, 238);
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.4px;
    color: rgb(55, 131, 59);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.noRecord {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: black;
  padding:16px;
}