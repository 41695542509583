.paidthru-container {
  .select-date-grid {
    display: flex;
    padding-left: 15px !important;
    .label {
      margin-right: 20px;
      font-weight: 400;
    }
  }
  .paidthrou-cell{
    max-width: 170px;
    word-break: break-all;
    min-width: 165px;
  }
}
