.title {
  font-size: 14px;
  font-weight: bold;
  background: white;
  margin-bottom: 5px;
  padding: 5px;
  padding-left: 10px;
  text-align: left;
}

.emailContainer {
  padding: 20px;
  .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 75%;

    .createDatasetBtn {
      border-radius: 0px;
      margin-left: 20px;
      margin-right: 0px;
      padding: 10px;
      margin-bottom: 10px;
      font-size: 12px;
      background: #0744ef;
      color: white;
    }
  }
}

.dataSet {
  width: 75%;
  background: #007d00;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  p {
    margin: 0;
    font-size: 14px;
    color: white;
  }
  span {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
  }
}

.settings {
  // width: 75%;

  .mappingTable {
    background: #eaf3ff;
    border-radius: 10px;
    p {
      margin: 0;
      background: #d1d1df;
      padding: 5px;
      color: darkblue;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-size: 14px;
      font-weight: 600;
    }
    .table {
      tbody {
        .MuiTableCell-root {
          border-bottom: 1px solid white !important;
        }
      }
    }
  }
  .jobDetails {
    background: #eaf3ff;
    margin-top: 5px;
    border-radius: 10px;
    padding: 5px 10px 10px 10px;

    .flexContainer {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-end;

      .label {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        margin-right: 20px;
        min-width: 150px;
        text-align: left;
      }

      .dateError {
        margin: 0;
        // margin-left: 10px;
        color: red;
        font-size: 14px;
      }
    }
  }
}

.dynamicValues {
  .title {
    font-size: 16px;
    font-weight: 500;
  }
  .jobDetails {
    background: #eaf3ff;
    margin-top: 5px;
    border-radius: 10px;
    padding: 5px 10px 10px 10px;

    .flexContainer {
      display: flex;
      margin-bottom: 15px;
      align-items: flex-end;

      .label {
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        margin-right: 20px;
        min-width: 150px;
        text-align: left;
      }
    }
  }

  .fieldsMain {
    margin-top: 40px;
    border: 1px solid darkblue;
    padding: 20px;
    border-radius: 10px;
    .fieldMainTitle {
      margin: 0;
      font-size: 15px;
      font-weight: 500;
      margin-right: 20px;
      min-width: 150px;
      text-align: left;
      margin-bottom: 5px;
    }
  }
  .fieldContainer {
    background: #eaf3ff;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-wrap: wrap;
    .fieldItem {
      padding: 5px 10px;
      background: #774879;
      color: #fff;
      border-radius: 10px;
      margin-right: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .delete {
        display: flex;
        color: #f51a1a;
        width: 24px;
        height: 24px;
        margin-left: 10px;
      }
    }
  }
}

.testEmail {
  min-width: 500px;

  .emailList {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .email {
      padding: 5px 10px;
      background: #36a8a7;
      color: white;
      margin: 5px;
      border-radius: 10px;
      border: 1px solid #096109;
    }
  }
}

.subjectStyle {
  margin-top: 5px;
  background: #e8e8e8;
  padding: 10px;
  align-items: center;
  padding-left: 15px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  margin-bottom: 15px;
  p {
    margin: 0;
    background: inherit;
  }
  .subjectTitle {
    margin: 0;
    font-size: 14px;
  }

  .content {
    margin: 0;
    margin-left: 5px;
    font-weight: 500;
  }
}
